import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "../Gallery/Gallery.module.css";
import GalleryImage1 from "../../assets/GalleryImages/GalleryImage1.webp";
import GalleryImage3 from "../../assets/GalleryImages/GalleryImage3.webp";
import GalleryImage4 from "../../assets/GalleryImages/GalleryImage4.webp";
import GalleryImage5 from "../../assets/GalleryImages/GalleryImage5.webp";
import GalleryImage6 from "../../assets/GalleryImages/GalleryImage6.webp";
import GalleryImage7 from "../../assets/GalleryImages/GalleryImage7.webp";
import GalleryImage8 from "../../assets/GalleryImages/GalleryImage8.webp";
import GalleryImage9 from "../../assets/GalleryImages/GalleryImage9.webp";
import GalleryImage10 from "../../assets/GalleryImages/GalleryImage10.webp";
import GalleryImage11 from "../../assets/GalleryImages/GalleryImage11.webp";
import GalleryImage12 from "../../assets/GalleryImages/GalleryImage12.webp";
import GalleryImage13 from "../../assets/GalleryImages/GalleryImage13.webp";
import GalleryImage15 from "../../assets/GalleryImages/GalleryImage15.webp";
import GalleryImage16 from "../../assets/GalleryImages/GalleryImage16.webp";
import GalleryImage17 from "../../assets/GalleryImages/GalleryImage17.webp";
import GalleryImage18 from "../../assets/GalleryImages/GalleryImage18.webp";
import GalleryStarsIcon from "../../assets/Icons/galleryStarsIcon.svg";
import "../../../src/styles.css";

function GallerySection() {
  return (
    <div className={styles.gallerySection}>
      <div className={styles.galleryHeadingContainer}>
        <div className={styles.galleryStarsLeft}>
          <img src={GalleryStarsIcon} alt="Active baby" />
        </div>
        <div className={styles.galleryHeading}>
          <span className="heading3 text-gray-500">Zavirite u našu </span>
          <span className="heading3 text-mint">slatku galeriju</span>
        </div>
        <div className={styles.galleryStarsRight}>
          <img src={GalleryStarsIcon} alt="Active baby" />
        </div>
      </div>
      <Carousel
        className={styles.carousel}
        showArrows
        infiniteLoop
        swipeable
        stopOnHover
        showStatus={false}
        dynamicHeight={false}
        showThumbs={true}
      >
        <div>
          <img src={GalleryImage15} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage16} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage1} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage3} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage4} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage5} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage6} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage7} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage8} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage9} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage10} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage11} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage12} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage13} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage17} alt="Active baby" />
        </div>
        <div>
          <img src={GalleryImage18} alt="Active baby" />
        </div>
      </Carousel>
    </div>
  );
}

export default GallerySection;
