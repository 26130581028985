import React, { useState } from "react";
import styles from "./Faq.module.css";
import "../../styles.css";
import ArrowDownIcon from "../../assets/Icons/arrowDownIcon.svg";

const FAQSection = () => {
  const faqData = [
    {
      question: "Za koji uzrast je predviđen Baby spa?",
      answer:
        "Za uzrast djece od 0-18mj (čim bebi otpadne pupak, možete ju dovesti).",
    },
    {
      question: "Kada je idealno vrijeme za dolazak na hidroterapiju i masažu?",
      answer:
        "Idealno je da beba bude sita (oko 30 min prije tretmana) i naspavana.",
    },
    {
      question: "Koliko traje tretman?",
      answer:
        "Predviđeno vrijeme trajanja termina je 1h. Sama hidroterapija traje maksimalno 30 min, ovisno o djetetovoj reakciji i prihvaćanju nove okoline. Svakim idućim dolaskom bebina tolerancija raste pa tako i vrijeme hidroterapije. Temperatura vode se održava u rasponu od 35-37 stupnjeva. Ukoliko niste s prijateljem, tretman je individualan, a mi smo posvećeni samo vašem djetetu.",
    },
    {
      question: "Koja je uloga šlaufa?",
      answer:
        "Vratna potpora u obliku šlaufa je provjeren i udoban za vašu bebu. Omogućuje joj da nesmetano pluta i uživa u gravity-free pokretima. Ne stvara natiske, a ovisno o dobi bebe odabire se pripadajuća veličina šlaufa.",
    },
    {
      question: "Kako se mogu naručiti?",
      answer:
        "Iako je telefonski put najbrži, našim malim klijentima je draže da nas ne ometaju pozivi. Idealan put komunikacije nam je putem maila, whatsapp porukom ili preko društvenih mreža.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqSection}>
      <div className={styles.faqSectionTitle}>
        <span className="heading3 text-mint">Najčešća</span>
        <span className="heading3 text-gray-500">pitanja</span>
      </div>
      <div className={styles.faqSectionAccordion}>
        {faqData.map((item, index) => (
          <div className={styles.faqItem} key={index}>
            <div
              className={`${styles.question} ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <span
                className={`label-large-regular ${
                  activeIndex === index ? "text-mint" : "text-gray-500"
                }`}
              >
                {item.question}
              </span>
              <img
                src={ArrowDownIcon}
                alt="Active Baby arrow"
                className={styles.arrow}
                style={{
                  transform:
                    activeIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </div>
            {activeIndex === index && (
              <div className={`${styles.answer} body-regular text-gray-300`}>
                {item.answer}
              </div>
            )}
            <span className={styles.divider} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
