import React, { useState } from "react";
import styles from "./Navbar.module.css";
import "../../styles.css";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const handleNavLinkClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const headerHeight = document.querySelector(".app-header").offsetHeight;
      const targetPosition = targetElement.offsetTop - headerHeight - 20;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
      if (window.innerWidth <= 768) {
        setShowMenu(false);
      }
    }
  };

  return (
    <nav className={styles.navbar}>
      <div
        className={styles.navbarToggle}
        onClick={() => setShowMenu(!showMenu)}
      >
        <span
          className={
            showMenu ? `${styles.menuBar} ${styles.closeBar1}` : styles.menuBar
          }
        ></span>
        <span
          className={
            showMenu ? `${styles.menuBar} ${styles.closeBar2}` : styles.menuBar
          }
        ></span>
        <span
          className={
            showMenu ? `${styles.menuBar} ${styles.closeBar3}` : styles.menuBar
          }
        ></span>
      </div>
      <ul className={showMenu ? `${styles.menu} ${styles.show}` : styles.menu}>
        <li>
          <a
            href="#about"
            onClick={handleNavLinkClick}
            className="label-medium-Comfortaa text-gray-500"
          >
            O nama
          </a>
        </li>
        <li>
          <a
            href="#services"
            onClick={handleNavLinkClick}
            className="label-medium-Comfortaa text-gray-500"
          >
            Usluge
          </a>
        </li>
        <li>
          <a
            href="#pricing"
            onClick={handleNavLinkClick}
            className="label-medium-Comfortaa text-gray-500"
          >
            Cjenik
          </a>
        </li>
        <li>
          <a
            href="#benefits"
            onClick={handleNavLinkClick}
            className="label-medium-Comfortaa text-gray-500"
          >
            Benefiti
          </a>
        </li>
        <li>
          <a
            href="#faq"
            onClick={handleNavLinkClick}
            className="label-medium-Comfortaa text-gray-500"
          >
            FAQ
          </a>
        </li>
        <li>
          <a
            href="#contact"
            onClick={handleNavLinkClick}
            className="label-medium-Comfortaa text-gray-500"
          >
            Kontakt
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
