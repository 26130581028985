import React from "react";
import Navbar from "./components/Navbar/Navbar";
import "./styles.css";
import Footer from "./components/Footer/Footer";
import GallerySection from "./components/Gallery/Gallery";
import ContactComponent from "./components/Contact/Contact";
import HeroSection from "./components/Hero/Hero";
import ActiveBabyLogo from "./assets/activeBabyLogo.png";
import FAQSection from "./components/FAQ/Faq";
import AboutBabySpa from "./components/AboutBabySpa/AboutBabySpa";
import AboutElaSection from "./components/AboutEla/AboutEla";
import PricingList from "./components/PricingList/PricingList";
import BabySpaServices from "./components/BabySpaServices/BabySpaServices";
import BenefitsSection from "./components/Benefits/Benefits";
function App() {
  return (
    <div className="App">
      <header className="app-header">
        <div className="logo-container">
          <img className="logo" src={ActiveBabyLogo} alt="Active Baby Logo" />
        </div>
        <Navbar />
      </header>
      <main>
        <HeroSection />
        <section id="about" className="section">
          <AboutBabySpa />
        </section>
        <section id="services" className="section">
          <BabySpaServices />
        </section>
        <section id="pricing" className="section">
          <PricingList />
        </section>
        <section id="benefits" className="section">
          <BenefitsSection />
        </section>
        <AboutElaSection />
        <section id="faq" className="section">
          <FAQSection />
        </section>
        <GallerySection />
        <section id="contact" className="section">
          <ContactComponent />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
