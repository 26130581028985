import React from "react";
import styles from "./BabySpaServices.module.css";
import "../../styles.css";
import HydrotherapyDesktop from "../../assets/hydrotherapyDesktop.svg";
import BabyMasaggeDesktop from "../../assets/babyMassageDesktop.svg";
import PregnancyMassageDesktop from "../../assets/pregnancyMassageDesktop.svg";
import HydrotherapyMobile from "../../assets/hydrotherapyMobile.svg";
import BabyMasaggeMobile from "../../assets/babyMassageMobile.svg";
import PregnancyMassageMobile from "../../assets/pregnancyMassageMobile.svg";
import HydrotherapyVideo from "../../assets/Videos/hydrotherapyVideo.mp4";
import VideoPlaceholder2 from "../../assets/VideoPlaceholder2.png";
import VideoPlaceholder3 from "../../assets/VideoPlaceholder3.png";
import VideoPlaceholder4 from "../../assets/VideoPlaceholder4.png";
import VideoPlaceholder5 from "../../assets/VideoPlaceholder5.png";
import PregnancyTrainingMobile from "../../assets/pregnancyTrainingMobile.svg";
import PregnancyTrainingDesktop from "../../assets/pregnancyTrainingDesktop.svg";
import MomAndBabyTrainingMobile from "../../assets/momAndBabyTrainingMobile.svg";
import MomAndBabyTrainingDesktop from "../../assets/momAndBabyTrainingDesktop.svg";

function BabySpaServices() {
  return (
    <div className={styles.babySpaServicesSection}>
      <div className={styles.babySpaServicesContainer}>
        <div className={styles.babySpaServicesTitle}>
          <span className="heading1 text-gray-500">Što nudimo?</span>
        </div>
        <div className={styles.babySpaServicesContent}>
          <div className={styles.babySpaServiceCloudContainer}>
            <img
              className={styles.serviceDescriptionImage}
              src={HydrotherapyDesktop}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceCloudContainerMobile}>
            <img
              className={styles.serviceDescriptionImage}
              src={HydrotherapyMobile}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceVideo}>
            <video
              className={styles.serviceDescriptionVideo}
              autoPlay
              loop
              muted
            >
              <source src={HydrotherapyVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className={styles.babySpaServicesContentVariation}>
          <div className={styles.babySpaServiceImageVariation}>
            <div className={styles.roundImageContainerVariation}>
              <img
                className={styles.serviceDescriptionImage}
                src={VideoPlaceholder2}
                alt="Active baby services"
              />
            </div>
          </div>
          <div className={styles.babySpaServiceCloudContainer}>
            <img
              className={styles.serviceDescriptionImage}
              src={BabyMasaggeDesktop}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceCloudContainerMobile}>
            <img
              className={styles.serviceDescriptionImage}
              src={BabyMasaggeMobile}
              alt="Active baby services"
            />
          </div>
        </div>
        <div className={styles.babySpaServicesContent}>
          <div className={styles.babySpaServiceCloudContainer}>
            <img
              className={styles.serviceDescriptionImage}
              src={PregnancyMassageDesktop}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceCloudContainerMobile}>
            <img
              className={styles.serviceDescriptionImage}
              src={PregnancyMassageMobile}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceImage}>
            <div className={styles.roundImageContainer}>
              <img
                className={styles.serviceDescriptionImage}
                src={VideoPlaceholder3}
                alt="Active baby services"
              />
            </div>
          </div>
        </div>
        <div className={styles.babySpaServicesContentVariation}>
          <div className={styles.babySpaServiceImageVariation}>
            <div className={styles.roundImageContainerVariation}>
              <img
                className={styles.serviceDescriptionImage}
                src={VideoPlaceholder4}
                alt="Active baby services"
              />
            </div>
          </div>
          <div className={styles.babySpaServiceCloudContainer}>
            <img
              className={styles.serviceDescriptionImage}
              src={PregnancyTrainingDesktop}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceCloudContainerMobileTraining}>
            <img
              className={styles.serviceDescriptionImage}
              src={PregnancyTrainingMobile}
              alt="Active baby services"
            />
          </div>
        </div>
        <div className={styles.babySpaServicesContent}>
          <div className={styles.babySpaServiceCloudContainer}>
            <img
              className={styles.serviceDescriptionImage}
              src={MomAndBabyTrainingDesktop}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceCloudContainerMobile}>
            <img
              className={styles.serviceDescriptionImage}
              src={MomAndBabyTrainingMobile}
              alt="Active baby services"
            />
          </div>
          <div className={styles.babySpaServiceImage}>
            <div className={styles.roundImageContainerTop200}>
              <img
                className={styles.serviceDescriptionImage}
                src={VideoPlaceholder5}
                alt="Active baby services"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BabySpaServices;
