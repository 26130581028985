import React from "react";
import styles from "./Footer.module.css";
import "../../styles.css";
import FacebookIcon from "../../assets/Icons/facebookIcon.svg";
import InstagramIcon from "../../assets/Icons/instagramIcon.svg";
import ActiveBabyLogo from "../../assets/activeBabyLogo.png";
import { handleScrollTo } from "../../utils/handleScrollTo";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.footerLogo}>
            <img
              className={styles.footerBrandLogo}
              src={ActiveBabyLogo}
              alt="Active Baby Logo"
            />
          </div>
          <div className={styles.footerServices}>
            <div className={styles.footerLinks}>
              <span className="body-regular uppercase-letters">
                Korisni linkovi
              </span>
              <span
                className="label-medium-Comfortaa cursor-pointer"
                onClick={() => handleScrollTo("about")}
              >
                O nama
              </span>
              <span
                className="label-medium-Comfortaa cursor-pointer"
                onClick={() => handleScrollTo("services")}
              >
                Usluge
              </span>
              <span
                className="label-medium-Comfortaa cursor-pointer"
                onClick={() => handleScrollTo("pricing")}
              >
                Cjenik
              </span>
              <span
                className="label-medium-Comfortaa cursor-pointer"
                onClick={() => handleScrollTo("benefits")}
              >
                Benefiti
              </span>
              <span
                className="label-medium-Comfortaa cursor-pointer"
                onClick={() => handleScrollTo("contact")}
              >
                Kontakt
              </span>
            </div>
            <div className={styles.footerSocials}>
              <span className="body-regular uppercase-letters">
                Pratite nas
              </span>
              <div className={styles.socialsLogoContainer}>
                <div>
                  <a
                    href=" https://www.facebook.com/profile.php?id=61556070370907"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles.socialsLogo}
                      src={FacebookIcon}
                      alt="Facebook Logo"
                    />
                  </a>
                </div>

                <div>
                  <a
                    href="https://www.instagram.com/activebabyspa?igsh=dmZuaXoydDNraGti"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles.socialsLogo}
                      src={InstagramIcon}
                      alt="Instagram Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className={styles.footerDivider} />
        <div className={styles.footerCopyright}>
          <span className="body-regular">
            © 2024 ActiveBaby. Sva prava pridržana.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
