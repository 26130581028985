export const handleScrollTo = (id) => {
  const targetElement = document.getElementById(id);
  if (targetElement) {
    const headerHeight = document.querySelector(".app-header").offsetHeight;
    const targetPosition = targetElement.offsetTop - headerHeight - 20;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  }
};
