import React from "react";
import styles from "./Contact.module.css";
import "../../styles.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import ClockIcon from "../../assets/Icons/clockIcon.svg";
import MessageIcon from "../../assets/Icons/messageIcon.svg";
import LocationIcon from "../../assets/Icons/locationIcon.svg";
import PhoneIcon from "../../assets/Icons/phoneIcon.svg";
import ActiveBabySpaEntrance from "../../assets/activeBabySpaEntrance.jpeg";
let DefaultIcon = L.icon({
  iconUrl: LocationIcon,
});
L.Marker.prototype.options.icon = DefaultIcon;

const googleMapsLink = "https://www.google.com/maps?q=43.509286,16.474161";
function ContactComponent() {
  return (
    <div className={styles.contactSection}>
      <div className={styles.contactContainer}>
        <div className={styles.contactInfoContainer}>
          <span className="heading3 text-gray-500 ">Kontaktirajte nas</span>
          <div className={styles.contactInfoDetails}>
            <div className={styles.contactInformations}>
              <img
                className={styles.contactIcons}
                src={PhoneIcon}
                alt="Active baby phone"
              />
              <div className={styles.contactInfoTitles}>
                <span className="label-large-medium text-gray-500">
                  Kontakt broj
                </span>
                <a href="tel:+385997513385" className={styles.contactLink}>
                  <span className="label-medium-Inter text-gray-300">
                    +385 99 751 3385
                  </span>
                </a>
              </div>
            </div>
            <div className={styles.contactInformations}>
              <img
                className={styles.contactIcons}
                src={MessageIcon}
                alt="Active baby mail"
              />
              <div className={styles.contactInfoTitles}>
                <span className="label-large-medium text-gray-500">
                  Email adresa
                </span>
                <a
                  href="mailto:info.activebaby@gmail.com"
                  className={styles.contactLink}
                >
                  <span className="label-medium-Inter text-gray-300">
                    info.activebaby@gmail.com
                  </span>
                </a>
              </div>
            </div>
            <div className={styles.contactInformations}>
              <img
                className={styles.contactIcons}
                src={LocationIcon}
                alt="Active baby location"
              />
              <div className={styles.contactInfoTitles}>
                <span className="label-large-medium text-gray-500">
                  Lokacija
                </span>
                <span className="label-medium-Inter text-gray-300">
                  kroz Smrdečac 41, 21000 Split
                </span>
              </div>
            </div>
            <div className={styles.contactInformations}>
              <img
                className={styles.contactIcons}
                src={ClockIcon}
                alt="Active baby clock"
              />
              <div className={styles.contactInfoTitles}>
                <span className="label-large-medium text-gray-500">
                  Radno vrijeme
                </span>
                <div className={styles.contactInfoTime}>
                  <span className="label-medium-Inter text-gray-500">
                    09:00 - 16:00
                  </span>
                  <span className="label-medium-Inter text-gray-400">
                    Ponedjeljak, utorak i petak
                  </span>
                </div>
                <div className={styles.contactInfoTime}>
                  <span className="label-medium-Inter text-gray-500">
                    14:00 - 19:00
                  </span>
                  <span className="label-medium-Inter text-gray-400">
                    Srijeda i četvrtak
                  </span>
                </div>
                <div className={styles.contactInfoTime}>
                  <span className="label-medium-Inter text-gray-500">
                    09:00 - 17:00
                  </span>
                  <span className="label-medium-Inter text-gray-400">
                    Subota
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mapContainer}>
          <div className={styles.contactCloudContainer}>
            <div className={styles.contactCloud} />
          </div>
          <div className={styles.contactMapContainer}>
            <MapContainer
              center={[43.509286, 16.474161]}
              zoom={30}
              className={styles.map}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[43.509286, 16.474161]}>
                <Popup>
                  <div className={styles.popupDiv}>
                    <img
                      className={styles.popupImg}
                      src={ActiveBabySpaEntrance}
                      alt="Active baby spa"
                    />
                    Active baby, kroz Smrdečac 41, 21000 Split <br />
                    <a
                      href={googleMapsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View on Google Maps
                    </a>
                  </div>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactComponent;
