import React from "react";
import styles from "./Benefits.module.css";
import "../../styles.css";
import MintCheckmarkIcon from "../../assets/Icons/mintCheckmarkIcon.svg";
function BenefitsSection() {
  return (
    <div className={styles.benefitsSection}>
      <div className={styles.benefitsSectionContentDesktop}>
        <div className={styles.benefitsDesktopImage} />
      </div>
      <div className={styles.benefitsSectionContentMobile}>
        <div className={styles.benefitsMobileImage}>
          <div className={styles.benefitsMobileContent}>
            <div className={styles.benefitsMobileContentTitle}>
              <span className="heading3 text-mint">
                Hidroterapija i masaža{" "}
                <span className="heading3 text-gray-500">
                  - što rade i zašto su bitne za bebe
                </span>
              </span>
            </div>
            <div className={styles.benefitsMobileContentInfo}>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Podrška zdravom rastu i razvoju
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Aktivira rad mozga
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Utječe na pravilan rad neurološkog sustava
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Podupire razvoj lokomotornog sustava
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Razvija motoričke sposobnosti
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Utječe pozitivno na hiper i hipotonus
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Umanjuje simptome kolika
                </span>
              </div>
              <div className={styles.benefitsMobileInfoItem}>
                <img
                  className={styles.checkmarkIcon}
                  src={MintCheckmarkIcon}
                  alt="Active baby benefits"
                />
                <span className="label-large-regular text-gray-500">
                  Umirujući efekt
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitsSection;
