import React from "react";
import styles from "./Hero.module.css";
import "../../styles.css";
import { handleScrollTo } from "../../utils/handleScrollTo";
import HeroShapeOverlay from "../../assets/BackgroundImages/heroShapeOverlay.png";
const HeroSection = () => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroOverlay}></div>
      <div className={styles.heroContent}>
        <div className={styles.heroContentTitles}>
          <span className="label-medium-Inter text-mint uppercase-letters">
            Iskustvo koje potiče razvoj
          </span>
          <span className="heading2 text-white">
            Otkrijte blagodati Baby spa tretmana za Vašu bebu.
          </span>
          <div
            className={styles.heroButton}
            onClick={() => handleScrollTo("benefits")}
          >
            <span className="label-medium-Inter text-white cursor-pointer">
              Saznaj više
            </span>
          </div>
        </div>
      </div>
      <img
        src={HeroShapeOverlay}
        alt="Active Baby"
        className={styles.bottomImage}
      />
    </div>
  );
};

export default HeroSection;
