import React from "react";
import styles from "./AboutEla.module.css";
import "../../styles.css";

function AboutElaSection() {
  return (
    <div className={styles.aboutElaSection}>
      <div className={styles.aboutElaContainer}>
        <div className={styles.textContainer}>
          <span className="label-medium-Inter text-gray-100 uppercase-letters">
            Kako je sve počelo
          </span>
          <div className={styles.mainHeadingAbout}>
            <span className="heading3 text-gray-500 ">Otkrivanje svijeta</span>
            <span className="heading3 text-mint">dječijeg razvoja</span>
          </div>
          <div className={styles.contentAbout}>
            <p className="body-regular text-gray-400">
              Ja sam Ela, prvostupnik fizioterapije. Kao dugogodišnja taekwondo
              trenerica najmlađih dobnih skupina, stekla sam ljubav prema radu s
              djecom.
            </p>
            <p className="body-regular text-gray-400">
              U radu s velikim brojem djece (urednog razvoja i s teškoćama),
              fizioterapija mi je pomogla razjasniti nedoumice o razlikama u
              motoričkim sposobnostima. Tim iskustvom zainteresirala sam se u
              period djetetova života u kojem se također te iste motoričke faze
              razvijaju.
            </p>
            <p className="body-regular text-gray-400">
              Tijekom rada u struci, naučila sam da je prevencija od izuzetne
              važnosti za razvojne vještine. Vođena time, misija kroz ActiveBaby
              koncept mi je pružiti svakom roditelju i bebi dodatan alat kako bi
              unaprijedili motorički, kognitivni i emocionalni razvoj.
            </p>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.image} />
        </div>
      </div>
    </div>
  );
}

export default AboutElaSection;
