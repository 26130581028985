import React from "react";
import styles from "./AboutBabySpa.module.css";
import "../../styles.css";
function AboutBabySpa() {
  return (
    <div className={styles.aboutBabySpaSection}>
      <div className={styles.aboutBabySpaContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.image} />
        </div>
        <div className={styles.textContainer}>
          <span className="label-medium-Inter text-gray-100  uppercase-letters">
            S ljubavlju za najmanje
          </span>
          <div className={styles.mainHeadingAbout}>
            <span className="heading3 text-gray-500 ">Upoznajte naš</span>
            <span className="heading3 text-mint">Baby Spa</span>
          </div>
          <div className={styles.contentAbout}>
            <p className="body-regular text-gray-400">
              Active Baby Concept nastao je iz želje da se bebama pruži
              opuštajuće iskustvo koje potiče razvoj. Dok su hidroterapija i
              masaža beba u svijetu prisutne već preko 15 godina, u Dalmaciji
              smo prvi s ovakvim konceptom pristupa s najslađom nam dobnom
              skupinom.
            </p>
            <p className="body-regular text-gray-400">
              Naš cilj je da Active Baby Concept postane sinonim stručnosti i
              sigurnosti te da olakšamo i uljepšamo djetinjstvo svakog mališana.
            </p>
          </div>
          <a
            className={styles.ctaButton}
            href="https://www.google.com/search?sca_esv=036e417f0382002b&sxsrf=ADLYWIKriUZ3OMUMEyAccPEIcMimOD-R6g:1733427872889&q=Active+Baby+Concept&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7mJYYaWoyxKBMVsiAODHp3ht2j53jYqNS0yXCA8geTx0lWSaOcY1HfVemS0DtUqAnWu7ozI%3D&uds=ADvngMgcma2krFDWAfXM9WWaYuEs4K1BE-TY8ROy8lu8LXpjVh3Em-JlKOukme-aZ_EH1nbvbrU9SiaaxZH6CaIC902MX7V5loYFpbXiQ6R-qghvlxW9E1w&sa=X&ved=2ahUKEwjBjO2EspGKAxXkgv0HHbQMD7UQ3PALegQIGhAE&biw=1920&bih=953&dpr=1"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Otvori Google recenzije u novom prozoru"
          >
            <span className="label-medium-Inter text-white cursor-pointer">
              Iskustva korisnika
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutBabySpa;
